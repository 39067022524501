import styled from "styled-components"
import { Text as Txt } from "../Text"
import { Block } from "../Block"
import Icon from "../Icon"
import { colors } from "../Style"

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
`

export const Text = styled(Txt)`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
`

export const StyledBlock = styled(Block)`
  min-height: 48rem;
  text-align: center;
`

export const StyledIcon = styled(Icon)`
  margin: 5rem auto;
  color: ${colors.yellow};
`
