import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Title, Text, StyledBlock, StyledIcon } from "./Style"
import { useStateValue } from "../../state"

export default function Success() {
  const data = useStaticQuery(
    graphql`
      query SuccessQuery {
        allContentfulSuccess {
          nodes {
            text {
              text
            }
            title
          }
        }
      }
    `
  )

  const [, dispatch] = useStateValue()
  const { title, text } = data.allContentfulSuccess.nodes[0]

  useEffect(() => {
    dispatch({
      type: "FORM_RESET",
    })
  }, [dispatch])

  return (
    <StyledBlock>
      <StyledIcon icon={["far", "flag-checkered"]} width={128} height={128} />
      <Title>{title}</Title>
      <Text>{text && text.text}</Text>
    </StyledBlock>
  )
}
